import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ReactNotification from 'react-notifications-component'
import HowToUse from "../../components/Index/HowToUse"
import VideoTutorial from "../../components/Index/VideoTutorial"

const TutorialPage = () => {
  return(
    <Layout>
      <ReactNotification />
      <SEO title="Tutorial" path="/tutorial"/>
      <HowToUse />
      <VideoTutorial />
    </Layout>
  )
}

export default TutorialPage
